import { useEffect, useState } from "react";
import axios from "axios";

const useAuthUser = () => {
  const [username, setUsername] = useState("");
  const [dadosUsuario, setDadosUsuario] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      const token = localStorage.getItem("token");

      if (token) {
        setLoading(true);
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_HOST}/users/verifyAuth`,
            { token },
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_TOKEN_API}`,
                "Content-Type": "application/json",
              },
            }
          );
          setUsername(response.data.user);
        } catch (error) {
          console.error("Error verifying authentication", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    getUser();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      if (username) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_HOST}/users/${username}`,
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_TOKEN_API}`,
              },
            }
          );

          if (response.status === 200) {
            setDadosUsuario(response.data);
          } else {
            console.error("Error fetching user data", response);
          }
        } catch (error) {
          console.error("Error fetching user data", error);
        }
      }
    };

    fetchUserData();
  }, [username]);

  return { username, dadosUsuario, loading };
};

export default useAuthUser;
