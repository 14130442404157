import React, { useEffect, useState } from "react";
import { Chart, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";

Chart.register(...registerables);

const stateMapping = {
  RJ: "Rio de Janeiro",
  CE: "Ceará",
  RN: "Rio Grande do Norte",
  SC: "Santa Catarina",
  RS: "Rio Grande do Sul",
  SP: "São Paulo",
  PB: "Paraíba",
  PE: "Pernambuco",
  PA: "Pará",
  MG: "Minas Gerais",
  RO: "Rondônia",
  MA: "Maranhão",
  ES: "Espírito Santo",
  DF: "Distrito Federal",
  MT: "Mato Grosso",
  GO: "Goiás",
  MS: "Mato Grosso do Sul",
  BA: "Bahia",
  PR: "Paraná",
  TO: "Tocantins",
  AL: "Alagoas",
  PI: "Piauí",
  RR: "Roraima",
  AM: "Amazonas",
  AC: "Acre",
  AP: "Amapá",
};

const BarLine = ({
  title = "Pedidos por Estado",
  backgroundColor = "rgba(128, 90, 213)",
  orders,
}) => {
  const [pedidosPorEstado, setPedidosPorEstado] = useState({});

  useEffect(() => {
    if (orders?.length > 0) {
      const estadoCount = orders?.reduce((acc, pedido) => {
        const estado = stateMapping[pedido.estado] || pedido.estado;
        acc[estado] = (acc[estado] || 0) + 1;
        return acc;
      }, {});

      const sortedEstadoCount = Object.entries(estadoCount).sort(
        (a, b) => b[1] - a[1]
      );
      const sortedPedidosPorEstado = Object.fromEntries(sortedEstadoCount);

      setPedidosPorEstado(sortedPedidosPorEstado);
    }
  }, [orders]);

  const barData = {
    labels: Object.keys(pedidosPorEstado),
    datasets: [
      {
        label: title,
        data: Object.values(pedidosPorEstado),
        backgroundColor,
      },
    ],
  };

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="flex flex-col w-full mt-4">
      <div className="container-bg flex flex-col w-full p-4 sm:p-8  sm:h-[400px] rounded-md border-[1px] border-color">
        <h1 className="flex justify-start w-full text-xl font-semibold text-primary ">
          {title}
        </h1>
        <div className="relative w-full h-full">
          <Bar data={barData} options={barOptions} />
        </div>
      </div>
    </div>
  );
};

export default BarLine;
