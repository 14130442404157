import React, { useState } from "react";
import Table from "./table";
import useUserOrders from "../hooks/Adm/useUserOrders";
import useAuthVerification from "../hooks/useAuthVerification";
import ContainerCard, { PedidoCardContent } from "./ContainerCard";

const UserOrdersComponent = () => {
  const { username, loading: authLoading } = useAuthVerification();
  const {
    orders = [],
    setOrders,
    loading: loadingOrders,
  } = useUserOrders(username);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const handleOrderClick = (order) => {
    setSelectedOrder(order);
  };

  return (
    <>
      <div className="flex pt-2 gap-3 flex-col justify-between w-full container-bg rounded-md border-[1px] border-color">
        <Table
          data={orders}
          setOrders={setOrders}
          title="Pedidos"
          loading={authLoading || loadingOrders}
          onRowClick={handleOrderClick}
        />
        {selectedOrder && (
          <ContainerCard
            title="Informações do pedido"
            onClose={() => setSelectedOrder(null)}
          >
            <PedidoCardContent pedido={selectedOrder} />
          </ContainerCard>
        )}
      </div>
    </>
  );
};

export default UserOrdersComponent;
