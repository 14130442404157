import React, { useState, useEffect } from "react";
import { X } from "lucide-react";
import ButtonSecondary from "./ButtonSecondary";
import Input from "./input";
import Button from "./Button";
import Select from "./Select";

const ModalFilter = ({ onClose, onApplyFilters, initialFilters }) => {
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    if (initialFilters) {
      setStatus(initialFilters.status);
      setStartDate(initialFilters.startDate);
      setEndDate(initialFilters.endDate);
    }
  }, [initialFilters]);

  const handleApplyFilters = () => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);

      if (isNaN(start.getTime()) || isNaN(end.getTime())) {
        alert("Por favor, insira datas válidas.");
        return;
      }

      if (end < start) {
        alert("A data final não pode ser menor que a data inicial.");
        return;
      }
    }

    onApplyFilters({ status, startDate, endDate });
    onClose(); 
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-end">
      <div className="relative flex flex-col container-bg items-start text-tertiary w-[350px] max-h-[96vh] rounded-md px-6 py-4 border-color border-2 mb-4 md:mb-0 bg-white m-4 modal-slide-in">
        <div className="flex flex-row justify-between items-center w-full pb-4">
          <h2 className="text-lg font-bold">Filtros</h2>
          <ButtonSecondary
            className="self-end text-gray-400"
            Icon={X}
            iconColor={"#9CA3AF"}
            iconSize={20}
            iconHoverColor={"#1F2937"}
            onClick={onClose}
          />
        </div>

        <div className="w-full mb-4">
          <label
            htmlFor="status"
            className="block text-sm font-medium text-gray-700"
          >
            Status do Pedido
          </label>
          <div className="mt-1">
            <Select
              placeholder="Selecione o Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              options={[
                { value: "processando", label: "processando" },
                { value: "taxa", label: "taxa" },
                { value: "recibo", label: "Recibo" },
                { value: "reembolso", label: "Reembolso" },
              ]}
            />
          </div>
        </div>

        <div className="w-full mb-4">
          <label
            htmlFor="start-date"
            className="block text-sm font-medium text-gray-700"
          >
            Data Inicial
          </label>
          <Input
            placeholder="Data Inicial"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>

        <div className="w-full mb-4">
          <label
            htmlFor="end-date"
            className="block text-sm font-medium text-gray-700"
          >
            Data Final
          </label>
          <Input
            placeholder="Data Final"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </div>
        <div className="flex  w-full h-[35px]">
          <div className="flex w-full justify-between ">
            <span
              className="cursor-pointer text-gray-500 hover:underline h-full flex items-center"
              onClick={() =>
                onApplyFilters({ status: "", startDate: "", endDate: "" })
              }
            >
              Remover Filtros
            </span>
            <Button text={"Aplicar Filtros"} onClick={handleApplyFilters} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalFilter;
