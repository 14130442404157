import React, { useState } from "react";
import logo from "../assets/logo.png";
import { Moon, Sun, ChevronDown, ChevronUp } from "lucide-react";
import useAuthUser from "../hooks/useAuthUser";
import useTheme from "../hooks/useTheme";

export default function Header({ onLogout, role }) {
  const { dadosUsuario, loading } = useAuthUser();
  const { theme, toggleTheme } = useTheme();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <header className="w-auto flex flex-row justify-between items-center px-6 py-7 h-[43px] container-bg shadow-sm border-b-[1.5px] border-color">
      <div className="items-center w-[200px] sm:flex hidden">
        <img src={logo} alt="Logo" className="flex items-center h-14" />
      </div>
      {role === "client" && (
        <div className="flex flex-row justify-between gap-2 w-[210px] whitespace-nowrap">
          {/* <a
            href="/"
            className="text-sm font-semibold text-neutral-500 hover:text-neutral-400"
          >
            Dashboard
          </a>
          <a
            href="/list-product"
            className="text-sm font-semibold text-neutral-500 hover:text-neutral-400 whitespace-nowrap"
          >
            Lista de pedidos
          </a> */}
        </div>
      )}
      {role === "adm" && (
        <div className="flex flex-row justify-between gap-2 w-[210px] whitespace-nowrap">
          <a
            href="/Adm"
            className="text-sm font-semibold text-neutral-500 hover:text-neutral-400"
          >
            Dashboard
          </a>
          <a
            href="/listUser"
            className="text-sm font-semibold text-neutral-500 hover:text-neutral-400"
          >
            Lista de usuários
          </a>
        </div>
      )}

      <div className="flex flex-row gap-2 items-center justify-end w-[200px]">
        <button
          className="text-neutral-500 hover:text-neutral-400"
          onClick={toggleTheme}
        >
          {theme === "light" ? (
            <Moon size={24} strokeWidth={1.8} />
          ) : (
            <Sun size={24} strokeWidth={1.8} />
          )}
        </button>
        <div className="flex flex-row items-center justify-center gap-1">
          <div className="hidden sm:block">
            <h3 className="text-sm font-semibold text-center text-neutral-500">
              {dadosUsuario.nome}
            </h3>
          </div>
          <div className="relative">
            <button
              className="flex items-center justify-center w-full text-neutral-500 hover:text-neutral-400 focus:outline-none"
              onClick={toggleDropdown}
            >
              <span className="chevron">
                {isDropdownOpen ? (
                  <ChevronUp size={20} />
                ) : (
                  <ChevronDown size={20} />
                )}
              </span>
            </button>
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 container-bg rounded-md py-1 container-bg shadow-sm border-[2px] border-color">
                <button
                  className="button-tertiary button-tertiary-dark-hover"
                  onClick={onLogout}
                >
                  Sair
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}
