import {useState} from 'react';
import axios from "axios";
import { formatDate } from '../../utils/date';

const useGetPedidosByDate = () => {
  const [loading, setLoading] = useState(false);
  const toast = (message, status) => {
    alert(`${status}: ${message}`);
  };

  const getPedidosByDate = async (username, initialDate, finalDate) => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/pedidos/user/${username}`,
        {
          dataInicial: formatDate(initialDate),
          dataFinal: formatDate(finalDate),
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_API}`,
          },
        }
      );
      setLoading(false);
      if (response.status === 200) {
        return response.data;
      } else {
        toast(`Não foi possível listar os pedidos do usuário ${username}.`, "Erro");
        return [];
      }
    } catch (error) {
      toast(`Ocorreu um erro ao buscar pedidos do usuário ${username}. Tente novamente.`, "Erro");
      return [];
    }
  };

  return {loading, getPedidosByDate};
};

export default useGetPedidosByDate;
