import React from "react";
import { useNavigate } from "react-router-dom";
import Input from "../input";
import ButtonSecondary from "../ButtonSecondary";
import { ChevronRight } from "lucide-react";
import useUsuarios from "../../hooks/useUsuarios";
import Breadcrumb from "../Breadcrumb";
import CreditoCard from "../CreditoCard";
import LoadingSpinner from "../Loading.js";

function ListUser() {
  const navigate = useNavigate();
  const { usuarios, loading } = useUsuarios();

  const handleNavigate = (username) => {
    navigate(`/dashAdmUser/user`, { state: { currentUser: username } });
  };

  if (loading) return <LoadingSpinner />;
  return (
    <div className="flex flex-col w-full p-4">
      {/* <Breadcrumb paths={[
        { label: 'Dashboard', route: '/dash' },
        { label: 'Lista de usuários', route: '/listUser' }
      ]} /> */}
      <h3 className="text-sm sm:text-normal font-base text-tertiary my-1 text-start">
        Lista de usuários
      </h3>
      <div className="flex flex-col sm:flex-row w-full justify-between border-b border-color">
        <div className="flex h-full justify-start items-center">
          <h1 className="text-xl sm:text-2xl font-semibold text-primary">
            Usuários
          </h1>
        </div>
        <div className="flex flex-col  sm:flex-row w-full sm:w-auto items-start sm:items-center justify-start gap-2 mb-2">
          <div className="flex w-full sm:w-[100px]">
            <p className="text-xs text-tertiary">{usuarios.length} usuários</p>
          </div>
          <div className="flex justify-center w-full">
          <Input
            type="text"
            placeholder="Pesquisar"
            className="w-full sm:w-[200px] h-[30px] rounded-md border-[1px] border-color"
          />
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-between items-center">
        {usuarios.map((usuario) => (
          <div key={usuario.id} className="border-b border-color w-full">
            <div className="flex flex-row   justify-between items-center gap-4 py-3">
              <div className="flex flex-row md:flex-row gap-2 w-full md:justify-between ">
                <div className="flex flex-col gap-1 w-72">
                  <h3 className="text-sm md:text-lg text-primary font-semibold">
                    {usuario.nome}
                  </h3>
                  <h3 className="text-xs md:text-sm text-tertiary">{usuario.email}</h3>
                <CreditoCard usuario={usuario} />
                </div>
              <div className="flex p-2 justify-center items-center ">
                <ButtonSecondary
                  Icon={ChevronRight}
                  iconSize="24px"
                  iconColor="#4b4b4b"
                  iconHoverColor="#000"
                  onClick={() => handleNavigate(usuario.user)}
                />
              </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ListUser;
