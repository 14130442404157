import { useEffect, useState } from "react";
import axios from "axios";

const useAuthVerification = () => {
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getUser = async () => {
      const token = localStorage.getItem("token");

      if (token) {
        setLoading(true);
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_HOST}/users/verifyAuth`,
            { token },
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_TOKEN_API}`,
                "Content-Type": "application/json",
              },
            }
          );

          setUsername(response.data.user);
        } catch (error) {
          console.error("Error verifying authentication", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    getUser();
  }, []);

  return { username, loading };
};

export default useAuthVerification;
