import React, { useState } from "react";
import { Image } from "@chakra-ui/react";
import logo from "../assets/logo.png";
import Button from "../components/Button";
import Input from "../components/input";
import Notification from "../components/Notification";
import ThemeToggle from "../components/ThemeToggle";
import LoadingSpinner from "../components/Loading.js";
import useTheme from "../hooks/useTheme";
import useNotification from "../hooks/useNotification";
import useLogin from "../hooks/useLogin";

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const { theme, toggleTheme } = useTheme();
  const { notification, setNotification, clearNotification } = useNotification();
  const {handleLogin, loading} = useLogin({ setNotification, onLogin });

  if(loading) {
    return  <LoadingSpinner />
  }
  return (
    <main className="flex min-h-screen flex-col items-center justify-center bg px-4 sm:px-10 pb-5 gap-4">
      <div className="absolute top-5 right-5">
        <ThemeToggle theme={theme} toggleTheme={toggleTheme} />
      </div>
      <div className="flex flex-col shadow-md container-bg rounded-md p-5 w-full max-w-md items-center justify-center">
        <div className="flex flex-row w-full justify-center items-center mb-4">
          <Image src={logo} alt="Logo" className="w-[150px] sm:w-[200px]" />
        </div>
        <div className="flex flex-col w-full gap-2 py-4">
          <div className="flex flex-col w-full">
            <div className="flex w-full text-tertiary font-semibold">E-mail</div>
            <Input
              placeholder="Insira seu e-mail"
              type="text"
              value={email}
              onChange={(e) => { setEmail(e.target.value); clearNotification(); }}
              focusBorderColor="#805ad5"
            />
          </div>
          <div>
            <div className="flex w-full text-tertiary font-semibold">Senha</div>
            <Input
              type="password"
              placeholder="Insira sua senha"
              value={senha}
              onChange={(e) => { setSenha(e.target.value); clearNotification(); }}
              focusBorderColor="#805ad5"
            />
          </div>
          <Notification notification={notification} />
          <Button onClick={() => handleLogin(email, senha)} text="ENTRAR">
            Entrar
          </Button>
        </div>
      </div>
    </main>
  );
};

export default Login;
