import React from "react";
import { X } from "lucide-react";
import ButtonSecondary from "./ButtonSecondary";
import StatusBadge from "./StatusBadge";

const ContainerCard = ({ title, onClose, children }) => {
  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-end">
      <div className="relative flex flex-col container-bg items-start text-tertiary w-[500px] max-h-[96vh] rounded-md px-6 py-4 border-color border-2 mb-4 md:mb-0 bg-white m-4 modal-slide-in">
        <div className="flex flex-row justify-between items-center w-full pb-4">
          <h1 className="text-2xl font-semibold text-primary">{title}</h1>
          <ButtonSecondary
            onClick={onClose}
            className="self-end text-gray-400"
            Icon={X}
            iconColor={"#9CA3AF"}
            iconSize={20}
            iconHoverColor={"#1F2937"}
          />
        </div>
        <div className="flex flex-col w-full gap-1">
          {children}
        </div>
      </div>
    </div>
  );
};

const formatDate = (dateString) => {
  try {
    const date = new Date(dateString).setDate(new Date(dateString).getDate() + 1);
    return new Intl.DateTimeFormat('pt-BR').format(date);
  } catch (error) {
    return "N/A";
  }
};

const parseProdutos = (produtos) => {
  try {
    return JSON.parse(produtos).join(", ");
  } catch (e) {
    return produtos;
  }
};

const formatCurrency = (value) => {
  return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
};

const formatCPF = (cpf) => {
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};

const formatCNPJ = (cnpj) => {
  return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
};

const fortmatTelefone = (telefone) => {
  return telefone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
};

const PedidoCardContent = ({ pedido }) => {
  if (!pedido) {
    return null;
  }

  return (
    <div className="flex flex-col w-full gap-1">
      <div className="flex flex-col bg-secondary w-full rounded-sm p-2 mb-4">
        <div>
          <p className="font-semibold text-tertiary">ID</p>
          <p>{pedido.id || "N/A"}</p>
        </div>
        <div>
          <p className="font-semibold text-tertiary">Data</p>
          <div className="flex items-center gap-1">
            <p className="text-sm text-tertiary">{formatDate(pedido.data)}</p>
          </div>
        </div>
        <div>
          <p className="font-semibold text-tertiary">Cliente</p>
          <div className="flex items-center gap-1">
            <p className="text-sm text-tertiary">{pedido.cliente || "N/A"}</p>
          </div>
        </div>
        <div>
          <p className="font-semibold text-tertiary">Email</p>
          <div className="flex items-center gap-1">
            <p>{pedido.email || "N/A"}</p>
          </div>
        </div>
        <div className="flex justify-start w-full gap-20">
          <div>
            <p className="font-semibold text-tertiary">CPF</p>
            <div className="flex items-center gap-1">
              <p className="text-sm text-tertiary">
                {formatCPF(pedido.cpf || "N/A")}
              </p>
            </div>
          </div>
          <div>
            <p className="font-semibold text-tertiary">Telefone</p>
            <div className="flex items-center gap-1">
              <p>{fortmatTelefone(pedido.telefone || "N/A")}</p>
            </div>
          </div>
        </div>
        <div>
          <p className="font-semibold text-tertiary">Endereço</p>
          <div className="flex items-center">
            <p>{`${pedido.endereco || "N/A"}, ${pedido.numero || "N/A"} - ${pedido.bairro || "N/A"}, ${pedido.cidade || "N/A"} - ${pedido.estado || "N/A"}, ${pedido.CEP || "N/A"}`}</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col bg-secondary w-full rounded-sm p-2 mb-4">
        <div className="flex justify-start w-full gap-20">
          <div>
            <p className="font-semibold text-tertiary">Usuário</p>
            <div className="flex items-center gap-1">
              <p className="text-sm text-tertiary">
                {pedido.Usuarios_user || "N/A"}
              </p>
            </div>
          </div>
          <div>
            <p className="font-semibold text-tertiary">CNPJ</p>
            <div className="flex items-center gap-1">
              <p>{formatCNPJ(pedido.Usuarios_Empresas_CNPJ || "N/A")}</p>
            </div>
          </div>
        </div>
        <div className="flex justify-start w-full gap-20">
          <div>
            <p className="font-semibold text-tertiary">Código de Rastreio</p>
            <div className="flex items-center gap-1">
              <p className="text-sm text-tertiary">
                PQ{pedido.codigo_rastreio}BR
              </p>
            </div>
          </div>
          <div>
            <p className="font-semibold text-tertiary">Valor</p>
            <div className="flex items-center gap-1">
              <p className="text-sm text-tertiary">
                {formatCurrency(pedido.valor || 0)}
              </p>
            </div>
          </div>
          <div>
            <p className="font-semibold text-tertiary">Nota</p>
            <div className="flex items-center gap-1">
              <p className="text-sm text-tertiary">
                {(pedido.nota || 0)}
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-between gap-2">
          <div>
            <p className="font-semibold text-tertiary">Status Pedido</p>
            <StatusBadge status={pedido.pedido_state || "N/A"} />
          </div>
          <div>
            <p className="font-semibold text-tertiary">Status Rastreio</p>
            <StatusBadge status={pedido.rastreio_state || "N/A"} />
          </div>
          <div>
            <p className="font-semibold text-tertiary">Status Nota Fiscal</p>
            <StatusBadge status={pedido.notafiscal_state || "N/A"} />
          </div>
        </div>
        <div>
          <p className="font-semibold text-tertiary">Produtos</p>
          <div className="flex items-center gap-1">
            <p>{parseProdutos(pedido.produtos) || "N/A"}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContainerCard;
export { PedidoCardContent };
