import React from 'react';

export default function ButtonSecondary({ text, onClick, Icon, disabled, iconSize, iconColor, iconHoverColor }) {
  return (
    <button
      onClick={onClick}
      className={`flex ${Icon ? 'items-center' : 'justify-center'} ${text ? (Icon ? 'gap-2' : '') : 'justify-center'} button button-secondary p-1 text-tertiary text-sm text-center font-medium transition duration-300 ease-in-out mb-4 md:mb-0 flex rounded-md`}
      disabled={disabled}
      style={{ position: 'relative' }} 
    >
      {Icon && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon 
            style={{ 
              width: iconSize, 
              height: iconSize, 
              color: iconColor, 
              transition: 'color 0.3s ease-in-out' 
            }} 
            className="icon"
          />
        </div>
      )}
      {text && <span>{text}</span>}
      <style jsx>{`
        .icon:hover {
          color: ${iconHoverColor};
        }
      `}</style>
    </button>
  );
}
