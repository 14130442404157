import React from 'react';

const CreditoCard = ({ usuario }) => {
  const creditoPositivo = usuario.credito > 0;
  const bgColor = creditoPositivo ? 'bg-blue-400 bg-opacity-10' : 'bg-red-400 bg-opacity-10';
  const textColor = creditoPositivo ? 'text-blue-800' : 'text-red-800';

  return (
    <div className={`flex w-[100px]  font-medium justify-center items-center rounded-md p-1 border-[1px] border-color ${bgColor}`}>
      <h3 className={`text-sm ${textColor}`}>{usuario.credito}</h3>
    </div>
  );
};

export default CreditoCard;
