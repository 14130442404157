import React, { useCallback, useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import MetricCard from "../MetricCard";
import useGetPedidos from "../../hooks/Adm/useGetPedidos";
import useGetPedidosByDate from "../../hooks/Adm/useGetPedidosByDate";
import useUsuarios from "../../hooks/useUsuarios";
import StatusCard from "../StatusCard";
import LoadingSpinner from "../Loading";
import FilterDayMonthWeek from "../FilterDayMonthWeek";
import {
  Users,
  Package,
  HandCoins,
  PackageCheck,
  PackageX,
  FileBox,
  TrendingUp,
  ReceiptText,
} from "lucide-react";
import ShimmerStatus from "../Shimmer/ShimmerStatus";
import ShimmerGraph from "../Shimmer/ShimmerGraph";
import ProductsGraph from "../ProductsGraph";
import Tabs from "../Tabs";
import ProductsTable from "../ProductsTable";

const tabs = [{ name: "Dashboard" }, { name: "Produtos mais populares" }];

function DashAdm() {
  const [productSoldList, setProductSoldList] = useState({});
  const [loadingGetPedidos, setLoadingGetPedidos] = useState(false);
  const { getPedidos } = useGetPedidos();
  const { getPedidosByDate } = useGetPedidosByDate();
  const { usuarios, loading: loadingUsuarios } = useUsuarios();
  const [hasFetched, setHasFetched] = useState(false);
  const [hasFetchedFilters, setHasFetchedFilters] = useState(false);
  const [totalPedidos, setTotalPedidos] = useState(0);
  const [valorTotalPedidos, setValorTotalPedidos] = useState(0);
  const [totalVendasDoDia, setTotalVendasDoDia] = useState(0);
  const [pedidosPorStatus, setPedidosPorStatus] = useState({
    processando: 0,
    taxa: 0,
    reembolso: 0,
    recibo: 0,
  });
  const [pedidosPorEmpresa, setPedidosPorEmpresa] = useState({});
  const [pedidosPorUsuario, setPedidosPorUsuario] = useState([]);
  const [filterDates, setFilterDates] = useState(null);

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const isToday = useCallback(
    (someDate) => {
      const today = filterDates?.finalDate ?? new Date();
      return (
        someDate.getDate() + 1 === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
      );
    },
    [filterDates]
  );

  const fetchPedidos = useCallback(
    async (dateParams) => {
      try {
        setLoadingGetPedidos(true);
        const allPedidos = await Promise.all(
          usuarios.map(async (user) => {
            const userPedidos = dateParams
              ? await getPedidosByDate(
                  user.user,
                  dateParams?.initialDate,
                  dateParams?.finalDate
                )
              : await getPedidos(user.user);

            return userPedidos.map((pedido) => ({
              ...pedido,
              usuario: user.user,
            }));
          })
        ).then((results) => results.flat());
        const counter = {};
        allPedidos.forEach((item) => {
          const produtos = item.produtos.split("][");
          produtos.forEach((produto) => {
            produto = produto.replace(/[\[\]"]/g, "");
            if (counter[produto]) {
              counter[produto]++;
            } else {
              counter[produto] = 1;
            }
          });
        });
        setProductSoldList(counter);
        setLoadingGetPedidos(false);
        const totalPedidos = allPedidos.length;
        const valorTotalPedidos = allPedidos.reduce(
          (total, pedido) => total + pedido.valor,
          0
        );
        console.log(new Date(allPedidos[0].data));
        const totalVendasDoDia = allPedidos
          .filter((pedido) => isToday(new Date(pedido.data)))
          .reduce((total, pedido) => total + pedido.valor, 0);

        const normalizeState = (state) => {
          if (!state) return "";
          return state.replace(/[^a-zA-Z]/g, "").toLowerCase();
        };

        const pedidosPorStatus = {
          processando: allPedidos.filter(
            (pedido) => normalizeState(pedido.pedido_state) === "processando"
          ).length,
          taxa: allPedidos.filter(
            (pedido) => normalizeState(pedido.pedido_state) === "taxa"
          ).length,
          reembolso: allPedidos.filter(
            (pedido) => normalizeState(pedido.pedido_state) === "reembolso"
          ).length,
          recibo: allPedidos.filter(
            (pedido) => normalizeState(pedido.pedido_state) === "recibo"
          ).length,
        };

        const pedidosPorEmpresa = allPedidos.reduce((acc, pedido) => {
          const empresa = pedido.empresa || "Empresa Desconhecida";
          acc[empresa] = (acc[empresa] || 0) + 1;
          return acc;
        }, {});

        const pedidosPorUsuario = usuarios
          .map((usuario) => ({
            name: usuario.user,
            pedidos: allPedidos.filter(
              (pedido) => pedido.usuario === usuario.user
            ).length,
          }))
          .sort((a, b) => b.pedidos - a.pedidos);

        setTotalPedidos(totalPedidos);
        setValorTotalPedidos(valorTotalPedidos);
        setTotalVendasDoDia(totalVendasDoDia);
        setPedidosPorStatus(pedidosPorStatus);
        setPedidosPorEmpresa(pedidosPorEmpresa);
        setPedidosPorUsuario(pedidosPorUsuario);
      } catch (error) {
        console.error("Erro ao buscar pedidos:", error);
      }
    },
    [usuarios, getPedidos, getPedidosByDate, isToday]
  );

  useEffect(() => {
    if (usuarios.length > 0 && !hasFetched) {
      setHasFetched(true);
      fetchPedidos();
    }
  }, [usuarios, fetchPedidos, hasFetched]);

  useEffect(() => {
    if (filterDates && !hasFetchedFilters) {
      setHasFetchedFilters(true);
      fetchPedidos(filterDates);
    }
  }, [filterDates, fetchPedidos, hasFetchedFilters]);

  if (loadingUsuarios) return <LoadingSpinner />;

  const top10Products = Object.entries(productSoldList)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 10);

  return (
    <Tabs tabs={tabs}>
      <div label="Dashboard" className="flex flex-col w-full h-full gap-2 p-4">
        <div className="flex flex-col justify-between w-full gap-3 md:flex-row ">
          <h1 className="text-2xl font-semibold text-primary">Dashboard</h1>
          <FilterDayMonthWeek
            setFilterDates={setFilterDates}
            setHasFetchedFilters={setHasFetchedFilters}
            setHasFetched={setHasFetched}
          />
        </div>
        <div className="flex flex-col justify-between w-full gap-2 mt-2 md:flex-row">
          {loadingGetPedidos ? (
            <>
              <ShimmerStatus />
              <ShimmerStatus />
              <ShimmerStatus />
              <ShimmerStatus />
            </>
          ) : (
            <>
              <MetricCard
                icon={<Users size={24} color="#fff" strokeWidth={1.5} />}
                label="Usuários"
                value={Array.isArray(usuarios) ? usuarios.length : 0}
                valueClass="text-secondary"
              />
              <MetricCard
                icon={<Package size={24} color="#fff" strokeWidth={1.5} />}
                label="Total de Pedidos"
                value={totalPedidos}
                valueClass="text-secondary"
              />
              <MetricCard
                icon={<HandCoins size={24} color="#fff" strokeWidth={1.5} />}
                label="Valor total de pedidos"
                value={formatCurrency(valorTotalPedidos)}
                valueClass="text-secondary"
              />
              <MetricCard
                icon={<TrendingUp size={24} color="#fff" strokeWidth={1.5} />}
                label="Total de vendas do dia"
                value={formatCurrency(totalVendasDoDia)}
                valueClass="text-secondary"
              />
            </>
          )}
        </div>
        <div className="flex flex-col w-full container-bg justify-start p-4 items-center shadow-sm gap-2 rounded-md border-[1px] border-color">
          <div className="flex flex-col w-full">
            <h1 className="flex justify-start w-full text-xl font-semibold text-primary">
              Status
            </h1>
            <h1 className="flex justify-start w-full text-sm font-base text-tertiary">
              Monitore o status de entrega de todos os pedidos em tempo real
            </h1>
          </div>
          <div className="flex flex-col justify-between w-full gap-4 md:flex-row">
            {loadingGetPedidos ? (
              <>
                <ShimmerStatus />
                <ShimmerStatus />
                <ShimmerStatus />
                <ShimmerStatus />
              </>
            ) : (
              <>
                <StatusCard
                  icon={<FileBox size={24} color="#3b82f6" strokeWidth={1.5} />}
                  label="Processando"
                  value={pedidosPorStatus.processando}
                  color="#CADEFF"
                />
                <StatusCard
                  icon={
                    <PackageX size={24} color="#ef4444" strokeWidth={1.5} />
                  }
                  label="Taxa"
                  value={pedidosPorStatus.taxa}
                  color="#FFD5D5"
                />
                <StatusCard
                  icon={
                    <PackageCheck size={24} color="#eab308" strokeWidth={1.5} />
                  }
                  label="Reembolso"
                  value={pedidosPorStatus.reembolso}
                  color="#FFEFD5"
                />
                <StatusCard
                  icon={
                    <ReceiptText size={24} color="#22c55e" strokeWidth={1.5} />
                  }
                  label="Recibo"
                  value={pedidosPorStatus.recibo}
                  color="#C5FFD5"
                />
              </>
            )}
          </div>
        </div>
        <div className="hidden md:flex flex-col w-full justify-start p-4 items-center shadow-sm gap-2 rounded-md border-[1px] border-color mt-4">
          <h1 className="flex justify-start w-full text-xl font-semibold text-primary">
            Quantidade de Pedidos por Usuário
          </h1>

          {loadingGetPedidos ? (
            <ShimmerGraph />
          ) : (
            <>
              <ResponsiveContainer width="100%" height={220}>
                <BarChart data={pedidosPorUsuario}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="pedidos" fill="rgba(128, 90, 213)" />
                </BarChart>
              </ResponsiveContainer>
              <ProductsGraph
                products={Object.entries(productSoldList)
                  .sort((a, b) => b[1] - a[1])
                  .slice(0, 20)}
              />
            </>
          )}
        </div>
      </div>
      <div label="Produtos mais populares">
        <ProductsTable orders={top10Products} />
      </div>
    </Tabs>
  );
}

export default DashAdm;
