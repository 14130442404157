import clsx from 'clsx';
import React from 'react';


export default function Input({ placeholder, type, onChange, Icon, className }) {
  return (
    <div className={clsx('flex flex-row container-secondary items-center text-tertiary w-full h-[auto] rounded-md p-2 border-color border-2 focus-within:border-[#805ad5] mb-4 md:mb-0 transition-colors', className)}>
      <div className='flex justify-center items-center'>
        {Icon && <Icon className="icon-color mr-2 items-center transition-colors duration-200" />}
      </div>
      <input
        className='w-full h-full px-2 bg-transparent border-none outline-none shadow-none'
        placeholder={placeholder}
        type={type}
        onChange={onChange}
        aria-label={placeholder}
      />
    </div>
  );
}

