import React from "react";
import ListUser from "../../components/Adm/ListUser";

const ListProduct = () => {
  return (
    <main className="flex flex-col items-center pb-5 m-10 h-fit">
     
     <div className="flex container-bg w-[100%] h-[auto] pb-10 rounded-md border-[1px] border-color shadow-sm">
        <ListUser />
     </div>
    </main>
  );
};

export default ListProduct;
