import React, { useState, useEffect } from "react";
import Button from "./Button";
import DatePicker from "react-datepicker";
import { Calendar, Trash } from "lucide-react";
import clsx from "clsx";
import "react-datepicker/dist/react-datepicker.css";

const FilterDayMonthWeek = ({
  setFilterDates,
  setHasFetchedFilters,
  setHasFetched,
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [showModal, setShowModal] = useState(false);
  const [hasFilters, setHasFilters] = useState(false);

  const today = new Date();

  const handleFilter = (days) => {
    setHasFetchedFilters?.(false);
    setHasFilters(true);
    if (days === 1) {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      setFilterDates({ initialDate: yesterday, finalDate: yesterday });
      return;
    }
    const initialDate = new Date();
    initialDate.setDate(initialDate.getDate() - days);
    setFilterDates({ initialDate, finalDate: today });
  };

  const handleCustomizarClick = () => {
    setShowModal(true);
  };

  const handleClearFilters = () => {
    setHasFetched?.(false);
    setFilterDates(null);
    setHasFilters(false);
  };

  const handleApplyClick = () => {
    setHasFetchedFilters?.(false);
    setFilterDates({ initialDate: startDate, finalDate: endDate });
    setShowModal(false);
    setHasFilters(true);
  };

  const handleCancelClick = () => {
    setShowModal(false);
  };

  return (
    <div>
      <div>
        <div className="flex flex-col container-bg md:flex-row border-color rounded md:rounded-none w-fit">
          <div className="flex w-40 flex-row border border-b-0 md:border-b border-color rounded md:rounded-none">
            <button
              className="flex flex-row items-center px-4 py-2 button text-tertiary button-tertiary focus:outline-none"
              onClick={handleCustomizarClick}
            >
              <Calendar size={20} className="mr-2" />
              Customizar
            </button>
          </div>
          <div className="hidden md:flex flex-row border md:border-l-0 border-color rounded md:rounded-none">
            <button
              className="p-2 w-full text-tertiary button focus:outline-none button-tertiary border-l border-color md:border-l-0"
              onClick={() => handleFilter(0)}
            >
              Hoje
            </button>
            <button
              className="p-2 w-full text-tertiary button focus:outline-none button-tertiary border-l border-color"
              onClick={() => handleFilter(1)}
            >
              Ontem
            </button>
            <button
              className="p-2 w-full text-tertiary button focus:outline-none button-tertiary border-l border-color"
              onClick={() => handleFilter(7)}
            >
              7D
            </button>
            <button
              className="p-2 w-full text-tertiary button focus:outline-none button-tertiary border-l border-color"
              onClick={() => handleFilter(14)}
            >
              14D
            </button>
            <button
              className="p-2 w-full text-tertiary button focus:outline-none button-tertiary border-l border-color"
              onClick={() => handleFilter(30)}
            >
              30D
            </button>
            {hasFilters && (
              <button
                className="flex bg-secondary items-center justify-center px-4 py-2 w-full button border-color border-l text-tertiary focus:outline-none"
                onClick={handleClearFilters}
              >
                <Trash size={20} color="#805ad5" />
              </button>
            )}
          </div>
        </div>

        {showModal && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-600 bg-opacity-50 z-50">
            <div className="container-bg p-4 rounded shadow-lg">
              <h2 className="text-primary text-lg mb-4">Selecione as datas</h2>
              <div className="mb-4">
                <label className="text-tertiary block mb-2">Data Inicial</label>
                <DatePicker
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={null}
                  className={clsx(
                    "border-color border-2 rounded-md p-2 w-full bg-transparent text-tertiary focus-within:border-[#805ad5] transition-colors"
                  )}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="mb-4">
                <label className="text-tertiary block mb-2">Data Final</label>
                <DatePicker
                  selected={endDate}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  className={clsx(
                    "border-color border-2 rounded-md p-2 w-full bg-transparent text-tertiary focus-within:border-[#805ad5] transition-colors"
                  )}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
              <div className="flex justify-between gap-4 w-full h-[35px]">
                <span
                  className="cursor-pointer text-gray-500 hover:underline h-full flex items-center"
                  onClick={handleCancelClick}
                >
                  Cancelar
                </span>
                <Button text="Aplicar" onClick={handleApplyClick}></Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterDayMonthWeek;
