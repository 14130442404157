import { useState, useEffect } from "react";
import axios from "axios";

const useUsuarios = () => {
  const [usuarios, setUsuarios] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = (message, status) => {
    alert(`${status}: ${message}`);
  };

  useEffect(() => {
    const getUsers = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_API_HOST}/users`,
          {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_TOKEN_API}`,
            },
          }
        );
        setLoading(false);

        if (response.status === 200) {
          setUsuarios(response.data);
        } else {
          toast("Não foi possível listar os usuários.", "Erro");
        }
      } catch (error) {
        toast("Ocorreu um erro ao buscar usuários. Tente novamente.", "Erro");
      }
    };

    getUsers();
  }, []);

  return {usuarios, loading};
};

export default useUsuarios;
