
const statusColors = {
  processando: { bg: 'bg-blue-400 bg-opacity-10', text: 'text-blue-800' },
  taxa: { bg: 'bg-red-400 bg-opacity-10', text: 'text-red-800' },
  reembolso: { bg: 'bg-yellow-400 bg-opacity-10', text: 'text-yellow-800' },
  recibo: { bg: 'bg-green-400 bg-opacity-10', text: 'text-green-800' },
  enviado: { bg: 'bg-green-400 bg-opacity-10', text: 'text-green-800'  }  
};

const StatusBadge = ({ status }) => {
  const { bg, text } = statusColors[status] || {};

  return (
    <div
      className={`flex items-center justify-center px-1 py-1 rounded-lg font-normal text-center ${bg} ${text}`}
    >
      <span className="ml-2 items-center">
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </span>
    </div>
  );
};

export default StatusBadge;
