import React from 'react';

export default function Select({ placeholder, value, onChange, options, Icon }) {
  return (
    <div className='flex flex-row container-secondary items-center text-tertiary w-full h-[auto] rounded-md p-2 border-color border-2 focus-within:border-[#805ad5] mb-4 md:mb-0 transition-colors'>
      <div className='flex justify-center items-center'>
        {Icon && <Icon className="icon-color mr-2 items-center transition-colors duration-200" />}
      </div>
      <select
        className='w-full h-full px-2 bg-transparent border-none outline-none shadow-none'
        value={value}
        onChange={onChange}
        aria-label={placeholder}
      >
        <option value="">{placeholder}</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>{option.label}</option>
        ))}
      </select>
    </div>
  );
}
