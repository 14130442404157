export default function ProductsTable({ orders }) {
  return (
    <div className="p-4 rounded-md shadow-sm container-bg">
      <table className="text-xs md:text-base">
        <thead>
          <tr>
            <th className="w-[10%] text-primary">Rank</th>
            <th className="w-full text-primary">Nome</th>
            <th className="w-full text-primary">Quantidade</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, index) => (
            <tr key={order.id} className="py-2">
              <td className="text-center text-tertiary">{index + 1}</td>
              <td className="text-tertiary">{order[0]}</td>
              <td className="text-center text-tertiary">{order[1]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
