import React, { useState, useEffect, useMemo } from "react";
import {
  ChevronLeft,
  ChevronRight,
  ArrowUp,
  ArrowDown,
  Filter,
} from "lucide-react";
import Button from "./Button";
import ButtonSecondary from "./ButtonSecondary";
import Input from "../components/input";
import LoadingSpinner from "./Loading";
import StatusBadge from "./StatusBadge";
import ModalFilter from "./ModalFilter";

const cleanProductName = (productList) => {
  let cleanedProductList = productList.replace(/[\[\]"]/g, "").toLowerCase();
  const maxLength = 30;
  if (cleanedProductList.length > maxLength) {
    cleanedProductList = `${cleanedProductList.substring(0, maxLength)}...`;
  }
  let words = cleanedProductList.split(" ");
  if (words.length > 0) {
    words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
  }
  return words.join(" ");
};

const formatCurrency = (value) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};

const removeSpecialCharacters = (str) => {
  return str.replace(/[^a-zA-Z0-9]/g, "");
};

const Table = ({ data = [], setOrders, title, loading, onRowClick }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isAscending, setIsAscending] = useState(true);
  const [noResults, setNoResults] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filters, setFilters] = useState({
    status: "",
    startDate: "",
    endDate: "",
  });

  const itemsPerPage = 20;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const applyFilters = (item) => {
    const { status, startDate, endDate } = filters;

    const statusMatch = status ? item.pedido_state === status : true;
    const startDateMatch = startDate
      ? new Date(item.data) >= new Date(startDate)
      : true;
    const endDateMatch = endDate
      ? new Date(item.data) <= new Date(endDate)
      : true;

    return statusMatch && startDateMatch && endDateMatch;
  };

  const filteredData = data.filter((item) => {
    const searchTermLower = searchTerm.toLowerCase();
    const emailMatch = item.email.toLowerCase().includes(searchTermLower);

    const codigoRastreio = item.codigo_rastreio
      ? item.codigo_rastreio.toString().toLowerCase()
      : "";
    const codigoRastreioSemPrefixoSufixo = codigoRastreio.replace(
      /^pq|br$/g,
      ""
    );
    const codigoRastreioAdaptado = `pq${codigoRastreioSemPrefixoSufixo}br`;
    const codigoRastreioMatch =
      codigoRastreio.includes(searchTermLower) ||
      codigoRastreioSemPrefixoSufixo.includes(searchTermLower) ||
      codigoRastreioAdaptado.includes(searchTermLower);

    return (emailMatch || codigoRastreioMatch) && applyFilters(item);
  });

  useEffect(() => {
    setNoResults(filteredData.length === 0);
  }, [filteredData]);

  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pageNumbers.push(1, 2, 3, 4, "...", totalPages);
      } else if (currentPage >= totalPages - 2) {
        pageNumbers.push(
          1,
          "...",
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages
        );
      } else {
        pageNumbers.push(
          1,
          "...",
          currentPage - 1,
          currentPage,
          currentPage + 1,
          "...",
          totalPages
        );
      }
    }
    return pageNumbers.map((number, index) => (
      <Button
        key={index}
        onClick={() => handlePageChange(number)}
        text={number.toString()}
        className={`px-2 py-1 mx-1 ${
          currentPage === number ? "bg-gray-300" : "bg-white"
        }`}
        disabled={number === "..."}
      />
    ));
  };

  const sortByDate = () => {
    const sortedData = [...data].sort((a, b) => {
      const dateA = new Date(a.data);
      const dateB = new Date(b.data);
      return isAscending ? dateA - dateB : dateB - dateA;
    });
    setOrders(sortedData);
    setIsAscending(!isAscending);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleApplyFilters = (filters) => {
    setFilters(filters);
  };

  const isMobile = window.innerWidth <= 768;

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <h1 className="flex justify-start w-full px-2 text-2xl font-semibold text-center text-primary">
            {title}
          </h1>
          <div className="flex flex-col justify-between w-full px-2 md:flex-row">
            <Input
              placeholder="Pesquisar"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="md:w-1/2"
            />
            <div className="flex flex-col justify-center mt-2 md:flex-row md:gap-4 md:justify-end md:mt-0">
              <Button
                text="Ordem por data"
                onClick={sortByDate}
                Icon={isAscending ? ArrowUp : ArrowDown}
                className="hidden md:block"
              />
              <Button
                text="Filtrar por data"
                onClick={toggleModal}
                Icon={Filter}
                className="hidden md:block"
              />
            </div>
          </div>
          {isMobile ? (
            <div className="flex flex-col w-full">
              <div className="min-w-full">
                {currentItems.length > 0 ? (
                  currentItems.map((item) => (
                    <div
                      key={item.id}
                      className="flex flex-row w-full justify-between px-2 py-2 items-center border-b-[1px] border-color"
                    >
                      <div className="flex flex-col gap-4 md:flex-row">
                        <div className="w-full md:w-[100px] flex flex-col items-start md:items-center md:block">
                          <span className="font-semibold md:hidden text-tertiary">
                            Data
                          </span>
                          <span className="text-tertiary">
                            {new Date(item.data).toLocaleString()}
                          </span>
                        </div>
                        <div className="w-full md:w-[200px] flex flex-col items-start md:items-center md:block">
                          <span className="font-semibold md:hidden text-tertiary">
                            Email
                          </span>
                          <span className="text-tertiary">{item.email}</span>
                        </div>
                      </div>
                      <div className="md:w-[50px] flex flex-col items-start md:items-center md:block">
                        <ButtonSecondary
                          Icon={ChevronRight}
                          iconSize="24px"
                          iconColor="#4b4b4b"
                          iconHoverColor="#000"
                          onClick={() => onRowClick(item)}
                          className="block mt-2 md:mt-0"
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="text-sm text-center text-tertiary">
                    Nenhum resultado encontrado para "{searchTerm}"
                  </div>
                )}
              </div>
            </div>
          ) : (
            <table className="min-w-full">
              <thead className="w-full border-b border-color">
                <tr className="flex justify-between p-3 bg-secondary text-tertiary">
                  {[
                    {
                      label: "Data",
                      className: "w-[100px] hidden sm:block text-tertiary",
                    },
                    {
                      label: "Email",
                      className: "w-[200px] hidden md:block text-tertiary",
                    },
                    {
                      label: "Produtos",
                      className: "w-[250px] hidden lg:block",
                    },
                    {
                      label: "Valor total",
                      className: "w-[100px] hidden xl:block",
                    },
                    {
                      label: "Status do pedido",
                      className: "w-[140px] hidden md:block ",
                    },
                    {
                      label: "Código de Rastreio",
                      className: "w-[200px] md:block",
                    },
                  ].map(({ label, className }, index) => (
                    <th
                      key={index}
                      className={`${className} text-center text-sm`}
                    >
                      {label}
                    </th>
                  ))}
                  <th className="w-[50px] text-center text-sm"></th>
                </tr>
              </thead>
              <div className="overflow-y-auto max-h-96">
                <tbody className="flex flex-col w-full">
                  {currentItems.length > 0 ? (
                    currentItems.map((item) => {
                      const date = new Date(item.data).setDate(
                        new Date(item.data).getDate() + 1
                      );
                      return (
                        <tr
                          key={item.id}
                          className="flex flex-col md:flex-row w-full justify-between px-2 py-2 items-center border-b-[1px] border-color"
                        >
                          {[
                            {
                              value: Intl.DateTimeFormat("pt-BR").format(date),
                              className:
                                "w-full md:w-[100px] hidden sm:block text-tertiary",
                            },
                            {
                              value: item.email,
                              className:
                                "w-full md:w-[200px] hidden md:block text-tertiary",
                            },
                            {
                              value: cleanProductName(item.produtos),
                              className:
                                "w-full md:w-[250px] hidden lg:block text-tertiary",
                            },
                            {
                              value: formatCurrency(item.valor),
                              className:
                                "w-full md:w-[100px] hidden xl:block text-tertiary",
                            },
                            {
                              value: removeSpecialCharacters(item.pedido_state),
                              className:
                                "w-full md:w-[140px] hidden md:block text-tertiary",
                              component: StatusBadge,
                            },
                            {
                              value: `PQ${item.codigo_rastreio}BR`,
                              className:
                                "w-full md:w-[200px] md:block text-xs md:text-sm",
                            },
                          ].map(
                            (
                              { value, className, component: Component },
                              index
                            ) => (
                              <td
                                key={index}
                                className={`${className} text-center text-sm text-tertiary`}
                              >
                                {Component ? (
                                  <Component status={value} />
                                ) : (
                                  value
                                )}
                              </td>
                            )
                          )}
                          <td className="w-full md:w-[50px] text-center text-sm">
                            <ButtonSecondary
                              Icon={ChevronRight}
                              iconSize="24px"
                              iconColor="#4b4b4b"
                              iconHoverColor="#000"
                              onClick={() => onRowClick(item)}
                              className="block"
                            />
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan="7"
                        className="text-sm text-center text-tertiary"
                      >
                        Nenhum resultado encontrado para "{searchTerm}"
                      </td>
                    </tr>
                  )}
                </tbody>
              </div>
            </table>
          )}
          <div className="flex flex-row items-center justify-between w-full px-4 py-2 sm:flex-row">
            <Button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              Icon={ChevronLeft}
              className="mb-2 sm:mb-0"
            />
            <div className="hidden gap-1 sm:flex">{renderPageNumbers()}</div>
            <Button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              Icon={ChevronRight}
            />
          </div>

          {isModalOpen && (
            <ModalFilter
              onClose={toggleModal}
              onApplyFilters={handleApplyFilters}
              initialFilters={filters}
            />
          )}
        </>
      )}
    </>
  );
};

export default Table;
