import React from "react";

const Notification = ({ notification }) => {
  if (!notification) return null;

  return (
    <div className={`w-full text-${notification.type === 'error' ? 'red' : 'green'}-500 text-sm mt-2 bg-${notification.type === 'error' ? 'red' : 'green'}-50 p-2 rounded-md`}>
      {notification.message}
    </div>
  );
};

export default Notification;
