import React from "react";
import Card from "../../components/card.js";
import DashUser from "../../components/Adm/DashUser.js";
function Dashboard({ onLogout }) {
  return (
    <>
      <main className="flex flex-col items-center px-10 pb-5 gap-4 m-5 rounded-md p-2">
        <DashUser showAdicionarButton={false} />
      </main>
    </>
  );
}

export default Dashboard;
