import React, { useState } from 'react';
import Button from './Button';

const Tabs = ({ tabs, children, buttonLabel, onButtonClick }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].name);

  return (
    <div className='w-full '>
      <div className="flex items-center justify-between border-b border-color pr-6 ">
        <div className="flex ">
          {tabs.map((tab) => (
            <button
              key={tab.name}
              className={`px-2 py-2 -mb-px border-b-2  ${
                activeTab === tab.name
                  ? 'border-[#805ad5] text-secondary font-semibold'
                  : 'border-transparent text-gray-500 hover:text-gray-700'
              }`}
              onClick={() => setActiveTab(tab.name)}
            > 
              {tab.name} {tab.count && <span className="ml-1 text-sm text-gray-500">({tab.count})</span>}
            </button>
          ))}
        </div>
        {/* <Button
          onClick={onButtonClick}
          text={buttonLabel}
        >
        </Button> */}
      </div>
      <div className="p-4">
        {React.Children.map(children, (child) => {
          if (child.props.label === activeTab) return child;
          return undefined;
        })}
      </div>
    </div>
  );
};

export default Tabs;
