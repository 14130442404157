import React from "react";

const StatusCard = ({ icon, label, value, color }) => (
  <div className="flex flex-col w-full md:w-[23%] h-[auto] justify-start items-start container-secondary rounded-md px-2 py-1 border-[1px] border-color">
    <div className="flex flex-row w-[auto] px-4 rounded-md justify-start gap-2 items-center">
      <div
        className="w-[auto] p-2 rounded-lg border-[1px] border-color items-center"
        style={{ backgroundColor: color }}
      >
        {icon}
      </div>
      <div>
        <h2 className="text-sm font-base text-tertiary my-1 text-start w-full">
          {label}
        </h2>
        <h2 className="text-xl font-base text-tertiary my-1 text-start w-full">
          {value}
        </h2>
      </div>
    </div>
  </div>
);

export default StatusCard;
