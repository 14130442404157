import { useEffect, useState } from "react";
import axios from "axios";

const useUserOrders = (username) => {
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      if (username) {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_HOST}/pedidos/user/${username}`,
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_TOKEN_API}`,
              },
            }
          );
          setLoading(false)

          if (response.status === 200) {
            setOrders(response.data || []);
          } else {
            console.error("Error fetching orders data", response);
          }
        } catch (error) {
          console.error("Error fetching orders data", error);
        }
      }
    };

    fetchOrders();
  }, [username]);

  return { orders, setOrders, loading };
};

export default useUserOrders;
