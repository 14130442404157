import React from "react";
import { Moon, Sun } from 'lucide-react';

const ThemeToggle = ({ theme, toggleTheme }) => (
  <button className="text-neutral-500 hover:text-neutral-400" onClick={toggleTheme}>
    {theme === 'light' ? <Moon size={24} strokeWidth={1.8} /> : <Sun size={24} strokeWidth={1.8} />}
  </button>
);

export default ThemeToggle;
