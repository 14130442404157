import React from "react";
import { Chart, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";

Chart.register(...registerables);

const ProductsGraph = ({
  title = "Produtos mais populares",
  backgroundColor = "rgba(128, 90, 213)",
  products,
}) => {
  const barData = {
    labels: products.map((product) =>
      product[0].substring(0, 20).concat("...")
    ),
    datasets: [
      {
        label: title,
        data: products.map((product) => product[1]),
        backgroundColor,
      },
    ],
  };

  const barOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="flex flex-col w-full mt-4">
      <div className="container-bg flex flex-col w-full p-4 sm:p-8  sm:h-[400px] rounded-md border-[1px] border-color">
        <h1 className="flex justify-start w-full text-xl font-semibold text-primary ">
          {title}
        </h1>
        <div className="relative w-full h-full">
          <Bar data={barData} options={barOptions} />
        </div>
      </div>
    </div>
  );
};

export default ProductsGraph;
