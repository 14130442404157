import { useEffect, useState } from "react";
import axios from "axios";

const useUserData = (username) => {
  const [dadosUsuario, setDadosUsuario] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (username) {
        setLoading(true);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_API_HOST}/users/${username}`,
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_TOKEN_API}`,
              },
            }
          );
          setLoading(false);

          if (response.status === 200) {
            setDadosUsuario(response.data);
          } else {
            console.error("Error fetching user data", response);
          }
        } catch (error) {
          console.error("Error fetching user data", error);
        }
      }
    };

    fetchUserData();
  }, [username]);

  return {dadosUsuario, loading};
};

export default useUserData;
