import React from "react";
import { useParams } from "react-router-dom";
import DashUser from "../../components/Adm/DashUser";

function DashAdmUser({ onLogout }) {
  const { id } = useParams();

  return (
    <>
      <main className="flex flex-col items-center md:px-10 pb-5 gap-4 m-5 rounded-md p-2 container-bg overflow-y-auto">
        <DashUser userId={id} />
      </main>
    </>
  );
}

export default DashAdmUser;
