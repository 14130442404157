import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function Breadcrumb({ paths }) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="breadcrumb">
      {paths.map((path, index) => (
        <span
          key={index}
          onClick={() => navigate(path.route)}
          className={`breadcrumb-item text-sm my-1 text-start w-full gap-2 ${
            location.pathname === path.route ? 'text-primary font-semibold' : 'text-tertiary font-normal'
          }`}
        >
          {path.label}
          {index < paths.length - 1 && ' > '}
        </span>
      ))}
    </div>
  );
}

export default Breadcrumb;
