import {useState} from 'react';
import axios from "axios";

const useLogin = ({ setNotification, onLogin }) => {
  const [loading, setLoading] = useState(false);
  const handleLogin = async (email, senha) => {
    if (!email) {
      setNotification({
        message: "Por favor, insira seu e-mail.",
        type: "error",
      });
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/users/auth`,
        { email, senha },
        { headers: { Authorization: `Bearer ${process.env.REACT_APP_TOKEN_API}` } }
      );
      setLoading(false);

      if (response.status === 200) {
        const { token, user, role } = response.data;
        localStorage.setItem("token", token);
        localStorage.setItem("user", user);
        localStorage.setItem("role", role);
        onLogin(token, user, role);
        setNotification({
          message: "Aguarde enquanto redirecionamos você para o painel de administração.",
          type: "success",
        });
      } else {
        setNotification({
          message: "Usuário ou senha inválido. Tente novamente.",
          type: "error",
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        setNotification({
          message: "Não encontramos uma conta com esse e-mail.",
          type: "error",
        });
      } else if (error.message.includes("Network Error")) {
        setNotification({
          message: "Problema de conexão de rede. Verifique sua conexão e tente novamente.",
          type: "error",
        });
      } else {
        setNotification({
          message: "Ocorreu um erro ao tentar fazer login. Por favor, tente novamente mais tarde.",
          type: "error",
        });
      }
    }
  };

  return {handleLogin, loading};
};

export default useLogin;
