import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import Login from "./routes/Login";
import Adm from "./routes/Adm/Adm";
import Dashboard from "./routes/User/Dashboard";
import Header from "./components/Header";
import axios from "axios";
import "./styles.css";
import ListProduct from "./routes/User/ListProduct";
import ListUser from "./routes/Adm/ListUser";
import LoadingSpinner from "../src/components/Loading";
import DashAdmUser from "./routes/Adm/DashAdmUser";

const verifyToken = async () => {
  const token = localStorage.getItem("token");
  if (token) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_HOST}/users/verifyAuth`,
        { token: token },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_API}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (!response.data.role) return "Erro";
      return response.data.role;
    } catch (error) {
      console.error("Error verifying authentication", error);
      return null;
    }
  }
  return null;
};

function App() {
  const navigate = useNavigate();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState(localStorage.getItem("user"));
  const [funcao, setFuncao] = useState(localStorage.getItem("role"));
  const [loading, setLoading] = useState(true);
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");

  const handleLogin = (token, user, funcao) => {
    setToken(token);
    setUser(user);
    setFuncao(funcao);
    localStorage.setItem("token", token);
    localStorage.setItem("user", user);
    localStorage.setItem("role", funcao);
  };

  const handleLogout = () => {
    setToken(null);
    setUser(null);
    setFuncao(null);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("role");
    navigate("/login");
  };

  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
    document.documentElement.setAttribute("data-theme", newTheme);
  };

  useEffect(() => {
    const checkToken = async () => {
      const funcao = await verifyToken();
      if (!funcao || funcao === "Erro") {
        handleLogout();
      } else {
        setFuncao(funcao);
      }
      setLoading(false);
    };

    const delayLoading = setTimeout(() => {
      checkToken();
    }, 600);

    document.documentElement.setAttribute("data-theme", theme);

    return () => clearTimeout(delayLoading);
  }, [theme]);

  const ProtectedRoute = ({ children, roleRequired }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [routeLoading, setRouteLoading] = useState(true);

    useEffect(() => {
      const verifyAuth = async () => {
        const funcao = await verifyToken();
        if (funcao && funcao === roleRequired) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
        setRouteLoading(false);
      };

      const delayLoading = setTimeout(() => {
        verifyAuth();
      }, 600);

      return () => clearTimeout(delayLoading);
    }, [roleRequired]);

    if (routeLoading || loading) {
      return <LoadingSpinner />;
    }

    if (!isAuthenticated) {
      if (roleRequired === "adm") {
        return <Navigate to="/dashboard" />;
      } else if (roleRequired === "client") {
        return <Navigate to="/adm" />;
      }
    }

    return children;
  };

  return (
    <>
      {token && <Header onLogout={handleLogout} role={funcao} />}
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute roleRequired="client">
              <Dashboard onLogout={handleLogout} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/adm"
          element={
            <ProtectedRoute roleRequired="adm">
              <Adm onLogout={handleLogout} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/login"
          element={
            token && funcao === "client" ? (
              <Navigate to="/dashboard" />
            ) : token && funcao === "adm" ? (
              <Navigate to="/adm" />
            ) : (
              <Login onLogin={handleLogin} />
            )
          }
        />
        <Route
          path="*"
          element={
            <Navigate to={funcao === "client" ? "/dashboard" : "/adm"} />
          }
        />
        <Route path="/list-product" element={<ListProduct />} />
        <Route path="/dashAdmUser/user" element={<DashAdmUser />} />
        <Route path="/listUser" element={<ListUser />} />
      </Routes>
    </>
  );
}

export default App;
