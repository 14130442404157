
import React from "react";

const MetricCard = ({ icon, iconClass, value, label, valueClass }) => (
  <div className="flex flex-col md:w-[25%] h-[auto] container-bg justify-start p-4 items-center gap-2 rounded-md border-[1px] border-color">
    <div className="flex flex-row w-full justify-start gap-2 items-center">
      <div className={`w-[auto] bg-[#805ad5] p-2 rounded-lg shadow-md items-center ${iconClass}`}>
        {icon}
      </div>
    </div>
    <h3 className={`text-2xl font-semibold my-1 text-start w-full ${valueClass}`}>
      {value}
    </h3>
    <h2 className="text-sm font-normal text-tertiary my-1 text-start w-full">
      {label}
    </h2>
  </div>
);

export default MetricCard;
