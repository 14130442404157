import React from "react";
import DashAdm from "../../components/Adm/DashAdmFull";

function Adm({ onLogout }) {
  return (
    <main className="flex flex-col  items-center pb-5 m-10 container-bg rounded-md border-[1px] border-color overflow-y-auto">
      <div className="flex w-[100%] h-[auto] pb-10 rounded-md">
        <DashAdm />
      </div>
    </main>
  );
}

export default Adm;
