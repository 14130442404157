import React from 'react';

export default function Button({ text, onClick, Icon, disabled }) {
  return (
    <button
      onClick={onClick}
      className={`flex items-center  button-primary justify-center ${Icon ? 'gap-2' : ''} p-2 rounded-md border-color border-[1px] text-[#805ad5] text-sm text-center font-semibold transition duration-300 ease-in-out mb-4 md:mb-0 sm:flex`}
      disabled={disabled}
    >
      {Icon && <Icon className="w-4 h-4 p-[1px] md:w-6 md:h-6" />}
      {text && <span className="text-xs p-[2px] md:text-sm lg:text-sm">{text}</span>}
    </button>
  );
}
