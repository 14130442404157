import React, { useState } from "react";
import ContainerCard, { PedidoCardContent } from "../../components/ContainerCard";
import UserOrdersComponent from "../../components/UserOrdersComponent";

const ListProduct = () => {
  const [showCard, setShowCard] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  const handleOrderClick = (order) => {
    setSelectedOrder(order);
    setShowCard(true);
  };

  return (
    <main className="flex flex-col items-center px-10 pb-5 mt-10">

    </main>
  );
};

export default ListProduct;
