import { useState } from "react";
import { useToast } from "@chakra-ui/react";
import Input from "./input";
import Button from "./Button";
const AdicionarCredito = ({ username, onCreditoAdicionado }) => {
  const [credito, setCredito] = useState(0);
  const toast = useToast();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      user: username,
      credito: Number(credito),
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_HOST}/users/addCredit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_TOKEN_API}`,
          },
          body: JSON.stringify(formData),
        }
      );

      const responseData = await response.json();

      if (response.ok) {
        toast({
          title: "Requisição enviada.",
          description: "Créditos atualizados com sucesso!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        onCreditoAdicionado(Number(credito));
      } else {
        if (response.status === 401) {
          toast({
            title: "Erro de autenticação.",
            description: "Por favor, faça login novamente.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          throw new Error("Erro ao enviar a requisição");
        }
      }
    } catch (error) {
      toast({
        title: "Erro.",
        description: "Houve um problema ao atualizar os créditos.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleCreditoChange = (e) => {
    let value = e.target.value.replace(/[^\d-]/g, "");

    const matches = value.match(/^-?\d*/);
    if (matches) {
      value = matches[0];
    }

    setCredito(value);
  };

  return (
    <div className="flex w-full">
      
      <form
        className="flex w-full text-center"
        onSubmit={handleSubmit}
      >
        <div className="flex flex-col w-full gap-2 mt-4">
          <Input
            placeholder={'Digite a quantidade de créditos'}
            type="number"
            id="credito"
            value={credito}
            onChange={handleCreditoChange}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500 sm:text-sm"
          />
        <Button
          text={'Adicionar'}
          type="submit"
        >
        </Button>
        </div>
      </form>
    </div>
  );
};

export default AdicionarCredito;
