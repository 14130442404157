import React from 'react';

const LoadingSpinner = () => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-primary">
            <div className="w-16 h-16 border-4 border-solid rounded-full animate-spin spinner"></div>
        </div>
    );
};

export default LoadingSpinner;
