import React, { useCallback, useEffect, useState } from "react";
import {
  HandCoins,
  Package,
  PackageCheck,
  PackageX,
  FileBox,
  TrendingUp,
  X,
  CreditCard,
  ReceiptText,
} from "lucide-react";
import { useLocation } from "react-router-dom";

import LoadingSpinner from "../../components/Loading";
import useAuthVerification from "../../hooks/useAuthVerification";
import useUserData from "../../hooks/useUserData";
import useUserOrders from "../../hooks/Adm/useUserOrders";
import MetricCard from "../../components/MetricCard";
import StatusCard from "../../components/StatusCard";
import Table from "../table";
import Tabs from "../Tabs";
import ContainerCard, { PedidoCardContent } from "../ContainerCard";
import BarLine from "../BarLine";
import AdicionarCredito from "../AdicionarCredito";
import Button from "../Button";
import ButtonSecondary from "../ButtonSecondary";
import ShimmerStatus from "../Shimmer/ShimmerStatus";
import ShimmerGraph from "../Shimmer/ShimmerGraph";
import useGetPedidosByDate from "../../hooks/Adm/useGetPedidosByDate";
import FilterDayMonthWeek from "../FilterDayMonthWeek";
import ProductsGraph from "../ProductsGraph";
import ProductsTable from "../ProductsTable";

const formatCurrency = (value) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
};

export default function DashUser({ showAdicionarButton = true }) {
  const { state } = useLocation();
  const { username, loading: loadingAuth } = useAuthVerification();
  const [currentUser, setCurrentUser] = useState("");
  const { dadosUsuario, loading: loadingUserData } = useUserData(currentUser);
  const { orders: initialOrders = [], loading: loadingOrders } =
    useUserOrders(currentUser);
  const { getPedidosByDate, loading: loadingPedidosByDate } =
    useGetPedidosByDate();

  const [filterDates, setFilterDates] = useState(null);
  const [hasFetched, setHasFetched] = useState(false);
  const [orders, setOrders] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [showCard, setShowCard] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showAdicionarCredito, setShowAdicionarCredito] = useState(false);

  useEffect(() => {
    if (currentUser) return;
    if (state?.currentUser) {
      setCurrentUser(state.currentUser);
    } else {
      setCurrentUser(username);
    }
  }, [username, state, currentUser]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isToday = useCallback(
    (someDate) => {
      const today = filterDates?.finalDate ?? new Date();
      return (
        someDate.getDate() + 1 === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
      );
    },
    [filterDates]
  );

  useEffect(() => {
    if (!filterDates) {
      setOrders(initialOrders);
      return;
    }
    if (!hasFetched) {
      setHasFetched(true);
      getPedidosByDate(
        currentUser,
        filterDates.initialDate,
        filterDates.finalDate
      ).then(setOrders);
    }
  }, [filterDates, initialOrders, getPedidosByDate, hasFetched, currentUser]);

  if (loadingAuth) {
    return <LoadingSpinner />;
  }

  const totalOrderValue = orders.reduce(
    (total, order) => total + order.valor,
    0
  );
  const totalSalesToday = orders
    .filter((order) => isToday(new Date(order.data)))
    .reduce((total, order) => total + order.valor, 0);

  const normalizeState = (state) => state.replace(/[^a-zA-Z]/g, "");
  const orderStatusCounts = {
    processando: orders.filter(
      (order) => normalizeState(order.pedido_state) === "processando"
    ).length,
    taxa: orders.filter(
      (order) => normalizeState(order.pedido_state) === "taxa"
    ).length,
    recibo: orders.filter(
      (order) => normalizeState(order.pedido_state) === "recibo"
    ).length,
    reembolso: orders.filter(
      (order) => normalizeState(order.pedido_state) === "reembolso"
    ).length,
  };

  const tabs = [
    { name: "Dashboard" },
    { name: "Lista de pedidos" },
    { name: "Produtos mais populares" },
  ];

  const handleRowClick = (order) => {
    setSelectedOrder(order);
    setShowCard(true);
  };

  const toggleAdicionarCredito = () => {
    setShowAdicionarCredito(!showAdicionarCredito);
  };

  const handleCreditoAdicionado = (novoCredito) => {
    dadosUsuario.credito += novoCredito;
    setShowAdicionarCredito(false);
  };

  const counter = {};
  if (orders && Object.keys(counter).length === 0) {
    orders.forEach((item) => {
      const produtos = item.produtos.split("][");
      produtos.forEach((produto) => {
        produto = produto.replace(/[\[\]"]/g, "");
        if (counter[produto]) {
          counter[produto]++;
        } else {
          counter[produto] = 1;
        }
      });
    });
  }

  const top10Products = Object.entries(counter)
    .sort((a, b) => b[1] - a[1])
    .slice(0, 10);

  return (
    <>
      <Tabs tabs={tabs}>
        <div label="Dashboard">
          <div className="flex flex-col">
            <div className="flex flex-col justify-between md:flex-row">
              <h1 className="pb-5 font-semibold md:text-2xl text-primary">
                Dashboard, {currentUser}!
              </h1>
              {showAdicionarButton && (
                <Button
                  onClick={toggleAdicionarCredito}
                  className="self-end text-gray-400"
                  Icon={CreditCard}
                  iconColor={"#9CA3AF"}
                  iconSize={24}
                  iconHoverColor={"#1F2937"}
                  text={"Adicionar Crédito"}
                />
              )}
            </div>
            <FilterDayMonthWeek
              setFilterDates={setFilterDates}
              setHasFetchedFilters={setHasFetched}
            />
          </div>
          <div className="flex flex-col justify-between w-full gap-2 my-4 md:flex-row">
            {loadingOrders || loadingUserData || loadingPedidosByDate ? (
              <>
                <ShimmerStatus />
                <ShimmerStatus />
                <ShimmerStatus />
                <ShimmerStatus />
              </>
            ) : (
              <>
                <MetricCard
                  icon={<CreditCard size={24} color="#fff" strokeWidth={1.5} />}
                  iconClass={dadosUsuario.credito < 0 ? "bg-red-500" : ""}
                  label="Créditos"
                  value={dadosUsuario.credito}
                  valueClass={
                    dadosUsuario.credito < 0 ? "text-red-500" : "text-secondary"
                  }
                />
                <MetricCard
                  icon={<Package size={24} color="#fff" strokeWidth={1.5} />}
                  label="Quantidade de pedidos"
                  value={orders.length}
                  valueClass="text-secondary"
                />
                <MetricCard
                  icon={<HandCoins size={24} color="#fff" strokeWidth={1.5} />}
                  label="Valor total de pedidos"
                  value={formatCurrency(totalOrderValue)}
                  valueClass="text-secondary"
                />
                <MetricCard
                  icon={<TrendingUp size={24} color="#fff" strokeWidth={1.5} />}
                  label="Total de vendas do dia"
                  value={formatCurrency(totalSalesToday)}
                  valueClass="text-secondary"
                />
              </>
            )}
          </div>
          <div className="flex flex-col w-full container-bg justify-start p-4 items-center shadow-sm gap-2 rounded-md border-[1px] border-color">
            <div className="flex flex-col w-full">
              <h1 className="flex justify-start w-full text-xl font-semibold text-primary">
                Status
              </h1>
              <h1 className="flex justify-start w-full text-sm font-base text-tertiary">
                Monitore o status de entrega de todos os pedidos em tempo real
              </h1>
            </div>
            <div className="flex flex-col justify-between w-full gap-4 md:flex-row">
              {loadingOrders || loadingUserData || loadingPedidosByDate ? (
                <>
                  <ShimmerStatus />
                  <ShimmerStatus />
                  <ShimmerStatus />
                  <ShimmerStatus />
                </>
              ) : (
                <>
                  <StatusCard
                    icon={
                      <FileBox size={24} color="#3b82f6" strokeWidth={1.5} />
                    }
                    label="Processando"
                    value={orderStatusCounts.processando}
                    color="#CADEFF"
                  />
                  <StatusCard
                    icon={
                      <PackageX size={24} color="#ef4444" strokeWidth={1.5} />
                    }
                    label="Taxa"
                    value={orderStatusCounts.taxa}
                    color="#FFD5D5"
                  />
                  <StatusCard
                    icon={
                      <PackageCheck
                        size={24}
                        color="#eab308"
                        strokeWidth={1.5}
                      />
                    }
                    label="Reembolso"
                    value={orderStatusCounts.reembolso}
                    color="#FFEFD5"
                  />
                  <StatusCard
                    icon={
                      <ReceiptText
                        size={24}
                        color="#22c55e"
                        strokeWidth={1.5}
                      />
                    }
                    label="Recibo"
                    value={orderStatusCounts.recibo}
                    color="#C5FFD5"
                  />
                </>
              )}
            </div>
          </div>
          {!isMobile && (
            <>
              {loadingOrders || loadingUserData || loadingPedidosByDate ? (
                <ShimmerGraph />
              ) : (
                <>
                  <BarLine
                    orders={orders}
                    title="Pedidos por Estado"
                    backgroundColor="rgba(128, 90, 213)"
                  />
                  <ProductsGraph
                    products={Object.entries(counter)
                      .sort((a, b) => b[1] - a[1])
                      .slice(0, 20)}
                  />
                </>
              )}
            </>
          )}
        </div>
        <div label="Lista de pedidos">
          <Table
            data={orders}
            setOrders={setOrders}
            title="Lista de pedidos"
            loading={loadingAuth}
            onRowClick={handleRowClick}
          />
        </div>
        <div label="Produtos mais populares">
          <ProductsTable orders={top10Products} />
        </div>
      </Tabs>
      {showAdicionarCredito && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="container-bg border-[1px] border-color  p-8 rounded-lg shadow-lg w-11/12 md:w-3/5 lg:w-1/3">
            <div className="flex flex-row justify-between">
              <div className="flex flex-col gap-1 ">
                <h2 className="font-semibold text-1xl text-primary">
                  Adicionar Crédito
                </h2>
                <h3 className="text-sm text-tertiary">
                  Os créditos serão somados ao valor atual.
                </h3>
              </div>
              <ButtonSecondary
                onClick={toggleAdicionarCredito}
                className="self-end text-gray-400"
                Icon={X}
                iconColor={"#9CA3AF"}
                iconSize={20}
                iconHoverColor={"#1F2937"}
              />
            </div>
            <AdicionarCredito
              username={currentUser}
              onCreditoAdicionado={handleCreditoAdicionado}
            />
          </div>
        </div>
      )}
      {showCard && selectedOrder && (
        <ContainerCard
          title="Informações do pedido"
          onClose={() => setShowCard(false)}
        >
          <PedidoCardContent pedido={selectedOrder} />
        </ContainerCard>
      )}
    </>
  );
}
